import React, { useState, useRef } from 'react';

const CareerPage = () => {

    const [formData, setFormData] = useState({
      name: "",
      email: "",
      position: "",
      resume: null,
      message: "",
    });
    const [showMessageField, setShowMessageField] = useState(false);
    const [formVisible, setFormVisible] = useState(false);
    const [result, setResult] = useState("");
  
    const formRef = useRef(null);
  
    const handleInputChange = (e) => {
      const { name, value } = e.target;
  
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
  
      if (name === "position" && value === "Other") {
        setShowMessageField(true);
      } else if (name === "position") {
        setShowMessageField(false);
      }
    };
  
    const handleFileChange = (e) => {
      setFormData((prevData) => ({
        ...prevData,
        resume: e.target.files[0],
      }));
    };
  
    const scrollToForm = () => {
      setFormVisible(true);
      formRef.current.scrollIntoView({ behavior: "smooth" });
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      setResult("Sending...");
  
      const web3FormData = new FormData();
      web3FormData.append("access_key", "45ea370d-181b-47ff-bfa8-5f2650b8ab71");
      web3FormData.append("name", formData.name);
      web3FormData.append("email", formData.email);
      web3FormData.append("position", formData.position);
      if (formData.resume) {
        web3FormData.append("resume", formData.resume);
      }
      if (showMessageField) {
        web3FormData.append("message", formData.message);
      }
  
      try {
        const response = await fetch("https://api.web3forms.com/submit", {
          method: "POST",
          body: web3FormData,
        });
  
        const data = await response.json();
        if (data.success) {
          setResult("Form Submitted Successfully");
          setFormData({
            name: "",
            email: "",
            position: "",
            resume: null,
            message: "",
          });
          setShowMessageField(false);
          e.target.reset();
        } else {
          setResult(data.message);
          console.error("Error", data);
        }
      } catch (error) {
        setResult("Submission Failed. Please try again.");
        console.error("Submission Error", error);
      }
    };

  const positions = [
    { title: 'React JS', description: 'Build interactive UIs using React.js, ensuring a smooth user experience.', icon: '⚛️' },
    { title: 'React Native', description: 'Develop cross-platform mobile apps for iOS and Android using React Native.', icon: '📱' },
    { title: 'VueJS', description: 'Design and build user interfaces with Vue.js, leveraging its flexibility and simplicity.', icon: '🔮' },
    { title: 'JavaScript', description: 'Create dynamic web applications with JavaScript, focusing on scalability and performance.', icon: '💻' },
    { title: 'Salesforce', description: 'Work with Salesforce platform to create customized CRM solutions for clients.', icon: '📊' },
    { title: 'AWS', description: 'Design and deploy scalable cloud applications using Amazon Web Services (AWS).', icon: '☁️' },
    { title: 'iOS', description: 'Develop native mobile applications for iOS with a focus on performance and UI.', icon: '🍏' },
    { title: 'Angular', description: 'Create dynamic and complex web apps using Angular with TypeScript integration.', icon: '🔧' },
    { title: 'Python', description: 'Leverage Python to build scalable back-end services, web apps, and data pipelines.', icon: '🐍' },
    { title: 'Android', description: 'Develop native Android applications using Java and Kotlin, ensuring seamless UX.', icon: '🤖' },
    { title: 'PHP', description: 'Build server-side web applications with PHP, ensuring performance and security.', icon: '🖥️' },
    { title: 'Ruby On Rails', description: 'Develop full-stack applications using Ruby on Rails with rapid prototyping.', icon: '💎' },
    { title: 'Node.js', description: 'Create server-side applications with Node.js, focusing on efficiency and scalability.', icon: '🌐' },
    { title: 'Java', description: 'Build robust enterprise-level applications using Java, focusing on security and scalability.', icon: '☕' },
    { title: 'Flutter', description: 'Create high-performance mobile apps with a single codebase using Flutter.', icon: '🦋' },
    { title: 'GoLang', description: 'Develop efficient and scalable applications using Go (Golang) for system-level programming.', icon: '🐹' },
    {
      "title": "Graphic Designer",
      "description": "Bring ideas to life with stunning visuals and creative designs that captivate and communicate effectively across all platforms.",
      "icon": "🎨"
    },
    { title: 'Other', description: 'For any other position not listed here, please specify in your message.', icon: '✨' }, // New "Other" card
  ];

  return (
    <div style={styles.container}>
      <header style={styles.header}>
        <h1>KotiBox Global Technologies</h1>
        <p>We are always looking for talented individuals to join our team!</p>
      </header>

      <section style={styles.positionsSection}>
        <h2 style={styles.sectionTitle}>Open Positions</h2>
        <div style={styles.positionCardsContainer}>
          {positions.map((position, index) => (
            <div key={index} style={styles.card}>
              <div style={styles.cardHeader}>
                <span style={styles.icon}>{position.icon}</span>
                <h3 style={styles.positionTitle}>{position.title}</h3>
              </div>
              <p style={styles.positionDescription}>{position.description}</p>
              <button
                style={styles.applyButton}
                onClick={() => {
                  setFormData((prevData) => ({ ...prevData, position: position.title }));
                  scrollToForm();
                  if (position.title === "Other") {
                    setShowMessageField(true);
                  }
                }}
              >
                Apply Now
              </button>
            </div>
          ))}
        </div>
      </section>

      <section style={{ ...styles.formSection, ...(formVisible ? styles.formSlideIn : {}) }} ref={formRef}>
        <h2>Interested? Fill Out the Form Below:</h2>
        <form onSubmit={handleSubmit} style={styles.form}>
          <input 
            type="text" 
            name="name" 
            placeholder="Your Name" 
            value={formData.name} 
            onChange={handleInputChange} 
            style={styles.input} 
            required
          />
          <input 
            type="email" 
            name="email" 
            placeholder="Your Email" 
            value={formData.email} 
            onChange={handleInputChange} 
            style={styles.input} 
            required
          />
          <select 
            name="position" 
            value={formData.position} 
            onChange={handleInputChange} 
            style={styles.input}
            required
          >
            <option value="">Select Position</option>
            {positions.map((position, index) => (
              <option key={index} value={position.title}>{position.title}</option>
            ))}
          </select>
          {showMessageField && (
            <textarea
              name="message"
              placeholder="Your Message (required for Other)"
              value={formData.message}
              onChange={handleInputChange}
              style={styles.input}
              required
            />
          )}
          <input 
            type="file" 
            name="resume" 
            onChange={handleFileChange} 
            style={styles.input}
            required
          />
          <button type="submit" style={styles.submitButton}>Submit</button>
        </form>
      </section>
    </div>
  );
};

const styles = {
  container: {
    fontFamily: 'Arial, sans-serif',
    margin: '0 auto',
    padding: '20px',
    maxWidth: '1200px',
    backgroundColor: '#000',
    color: '#fff',
  },
  header: {
    textAlign: 'center',
    padding: '30px',
    backgroundColor: '#333',
    color: '#fff',
    animation: 'fadeIn 2s ease-out',
  },
  positionsSection: {
    marginTop: '30px',
  },
  sectionTitle: {
    fontSize: '36px',
    fontWeight: '600',
    marginBottom: '30px',
    color: '#ff9900',
    animation: 'fadeIn 2s ease-out',
  },
  positionCardsContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(280px, 1fr))',
    gap: '30px',
    justifyItems: 'center',
  },
  card: {
    backgroundColor: '#fff',
    borderRadius: '10px',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
    padding: '20px',
    width: '100%',
    textAlign: 'center',
    transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease, background-color 0.3s ease',
  },
  cardHeader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '20px',
    animation: 'fadeIn 2s ease-out',
  },
  icon: {
    fontSize: '40px',
    marginRight: '10px',
    color: '#ff9900',
  },
  positionTitle: {
    fontSize: '24px',
    fontWeight: '500',
    color: '#ff9900',
  },
  positionDescription: {
    fontSize: '16px',
    color: '#777',
    marginBottom: '20px',
  },
  applyButton: {
    backgroundColor: '#333',
    color: '#fff',
    padding: '12px 24px',
    fontSize: '18px',
    border: 'none',
    cursor: 'pointer',
    borderRadius: '5px',
    transition: 'background-color 0.3s ease',
  },
  formSection: {
    marginTop: '50px',
    padding: '30px',
    backgroundColor: '#222',
    borderRadius: '8px',
    display: 'none',
    animation: 'fadeIn 2s ease-out',
  },
  formSlideIn: {
    display: 'block',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  input: {
    padding: '12px',
    marginBottom: '20px',
    fontSize: '16px',
    borderRadius: '5px',
    border: '1px solid #444',
    backgroundColor: '#333',
    color: '#fff',
  },
  submitButton: {
    backgroundColor: '#ff9900',
    color: '#fff',
    padding: '12px 24px',
    fontSize: '18px',
    border: 'none',
    cursor: 'pointer',
    borderRadius: '5px',
  },
};

export default CareerPage;
