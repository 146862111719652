import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  const pageStyle = {
    fontFamily: "Arial, sans-serif",
    backgroundColor: "#101214", // Dark background
    color: "#f8f9fa", // Light text
    padding: "2rem",
    minHeight: "60vh",
  };

  const containerStyle = {
    maxWidth: "900px",
    margin: "0 auto",
  };

  const dropdownStyle = {
    marginBottom: "1rem",
    padding: "1.2rem",
    backgroundColor: "#1c1f23", // Slightly lighter dark
    borderRadius: "10px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.6)",
    cursor: "pointer",
    transition: "all 0.3s ease",
  };

  const dropdownHoverStyle = {
    ...dropdownStyle,
    transform: "scale(1.02)",
    boxShadow: "0 6px 12px rgba(0, 0, 0, 0.8)",
  };

  const headingStyle = {
    color: "#FF7300", // Saffron color
    fontSize: "1.4rem",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  };

  const contentStyle = {
    marginTop: "1rem",
    padding: "1rem",
    backgroundColor: "#25292e", // Slightly lighter shade for content
    borderRadius: "8px",
    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.8)",
  };

  const buttonStyle = {
    marginTop: "1rem",
    backgroundColor: "#FF7300",
    color: "#ffffff",
    border: "none",
    padding: "0.5rem 1rem",
    borderRadius: "5px",
    fontSize: "1rem",
    cursor: "pointer",
    transition: "background-color 0.3s ease",
  };

  const iconStyle = {
    marginLeft: "10px",
    transform: "rotate(90deg)", // Default icon rotation
    transition: "transform 0.3s ease",
  };

  const rotatedIconStyle = {
    ...iconStyle,
    transform: "rotate(270deg)", // Rotated for expanded state
  };

  const sections = [
    {
      title: "Planning and Consultation",
description:
  "Our journey begins with understanding your vision. Together, we shape a comprehensive project roadmap, defining clear milestones and aligning with your goals. Through collaborative discussions, we refine your ideas to target the right audience and achieve your desired outcomes.",
items: [
  "Vision and Goals Alignment: Dive deep into understanding your objectives, audience, and aspirations to ensure a focused approach.",
  "Strategic Roadmap Development: Craft a detailed project plan with defined milestones, timelines, and deliverables.",
  "Comprehensive Scope Definition: Clearly outline essential features, functionalities, and requirements to align expectations.",
  "Optimized Resource Allocation: Strategically assign team roles, tools, and budgets to streamline project execution.",
],

    },
    {
      title: "Design",
description:
  "Our expert designers bring your vision to life with user-centric and visually captivating solutions. We prioritize creating seamless and engaging experiences that leave a lasting impression on your audience.",
items: [
  "Wireframing: Design intuitive wireframes to map the structure and layout, ensuring clarity and focus.",
  "UI/UX Design: Develop visually stunning and highly functional interfaces that elevate user engagement and satisfaction.",
  "Prototype Development: Build interactive prototypes to gather valuable user feedback and refine the design.",
  "Design Finalization: Share the design for your review, incorporating your feedback to deliver a perfectly tailored solution.",
],

    },
    {
      title: "Development",
description:
  "Our seasoned developers transform your design into a fully functional solution, combining precision and innovation. With regular updates and milestone deliverables, we ensure complete transparency and keep you in control throughout the development process.",
items: [
  "Frontend Development: Implement responsive, user-friendly interfaces optimized for compatibility across devices.",
  "Backend Development: Develop robust server-side logic and seamless integration for efficient performance.",
  "Progress Tracking: Deliver regular updates and milestone reports to ensure clarity and alignment.",
  "Quality Assurance: Conduct thorough testing and debugging to guarantee functionality and reliability.",
],

    },
    {
      title: "Testing",
description:
  "Our dedicated QA specialists rigorously test every facet of your product to ensure perfection before launch. By identifying and resolving potential issues, we guarantee a seamless and reliable user experience.",
items: [
  "Functionality Testing: Verify all features and functionalities to ensure accurate and dependable performance.",
  "Usability Testing: Analyze the user interface and experience for intuitive navigation and ease of use.",
  "Performance Testing: Evaluate speed, responsiveness, and load handling under diverse scenarios.",
  "Security Testing: Perform comprehensive security assessments to detect and address vulnerabilities.",
],

    },
    {
      title: "Deployment",
      description:
        "After finalizing every detail, we carefully select the optimal deployment strategies to bring your project to life. With meticulous preparation, we ensure a seamless launch and set the stage for your success.",
      items: [
        "Hosting Setup: Identify and configure the most suitable hosting services tailored to your needs.",
        "Final Checks: Conduct comprehensive pre-launch checks and configurations to guarantee readiness.",
        "Strategic Launch Plan: Outline and implement a detailed launch strategy, including marketing and promotional initiatives.",
        "Go Live: Deploy your product with precision, ensuring peak performance and accessibility.",
      ],
      
    },
    {
      title: "Maintenance",
      description:
        "Our commitment doesn't end at launch. We provide ongoing support to ensure your product stays up-to-date, secure, and bug-free.",
      items: [
        "Regular Updates: Implement software and security updates regularly.",
        "Monitoring: Continuously monitor performance and security to prevent issues.",
        "Bug Fixes: Address any technical issues or bugs that arise post-launch.",
        "Performance Optimization: Optimize speed and functionality based on user feedback and analytics.",
      ],
    },
  ];

  const [activeDropdown, setActiveDropdown] = useState(null);

  const toggleDropdown = (index) => {
    setActiveDropdown(activeDropdown === index ? null : index);
  };

  return (
    <div style={pageStyle}>
      <div className="container" style={containerStyle}>
        <h1 className="text-center mb-4" style={{ color: "fffffff" }}>
          Agile Mobile App Development Process
        </h1>
        {sections.map((section, index) => (
          <div
            key={index}
            style={activeDropdown === index ? dropdownHoverStyle : dropdownStyle}
            onClick={() => toggleDropdown(index)}
          >
            <div style={headingStyle}>
              {section.title}
              <span
                style={activeDropdown === index ? rotatedIconStyle : iconStyle}
              >
                &#9660;
              </span>
            </div>
            {activeDropdown === index && (
              <div style={contentStyle}>
                <p>{section.description}</p>
                <ul>
                  {section.items.map((item, idx) => (
                    <li key={idx}>{item}</li>
                  ))}
                </ul>
                <button style={buttonStyle}>Learn More</button>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default App;
