import React, { useState } from 'react';
import './Navbar.css';
import { Link } from 'react-router-dom';

import kotiboxlogo from '../imgvdo/kotiboxlogo.png';

const Navbar = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isServicesOpen, setIsServicesOpen] = useState(false);
    const [isIndustriesOpen, setIsIndustriesOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const toggleServices = () => {
        setIsServicesOpen(!isServicesOpen);
        setIsIndustriesOpen(false);
    };

    const toggleIndustries = () => {
        setIsIndustriesOpen(!isIndustriesOpen);
        setIsServicesOpen(false);
    };

    const closeMenu = () => {
        setIsMenuOpen(false);
    };

    return (
        <div className="navbar">
            <div className="logo">
            <Link to="/">
    <img src={kotiboxlogo} alt="KotiBox Logo" className="logo-img" />
</Link>
            </div>
            <div className={`menu ${isMenuOpen ? 'open' : ''}`}>
                <a href="./Aboutt" onClick={closeMenu}>About</a>
                <a href="#" onClick={toggleServices} className={isServicesOpen ? 'dropdown-active' : ''}>Services</a>
                {isServicesOpen && (
                   <div className="dropdown services">
                   <Link to="/SoftwareDevelopment" onClick={closeMenu}>Software Development</Link>
                   <Link to="/ECommerceServices" onClick={closeMenu}>eCommerce Services</Link>
                   <Link to="/DigitalMarketing" onClick={closeMenu}>Digital Marketing</Link>
                   <Link to="/CRMDevelopment" onClick={closeMenu}>CRM Development</Link>
                   <Link to="/FullStack" onClick={closeMenu}>Full Stack Development</Link>
                   <Link to="/ContentMarketing" onClick={closeMenu}>Content Marketing</Link>
                   <Link to="/ArtificialIntelligence" onClick={closeMenu}>Artificial Intelligence</Link>
                   <Link to="/AppDevelopment" onClick={closeMenu}>App Development</Link>
                   <Link to="/Blockchain" onClick={closeMenu}>Blockchain</Link>
                   <Link to="/ERPDevelopment" onClick={closeMenu}>ERP Development</Link>
                   <Link to="/WebsiteDevelopment" onClick={closeMenu}>Website Development</Link>
                   <Link to="/UIUXAndVideography" onClick={closeMenu}>UI/UX & Videography</Link>
               </div>
                )}
                <a href="#" onClick={toggleIndustries} className={isIndustriesOpen ? 'dropdown-active' : ''}>Industries</a>
                {isIndustriesOpen && (
                    <div className="dropdown industries">
                    <Link to="/BankingAndFinance" onClick={closeMenu}>Banking & Finance</Link>
                    <Link to="/EcommerceSolutions" onClick={closeMenu}>Ecommerce Solutions</Link>
                    <Link to="/FoodAndRestaurants" onClick={closeMenu}>Food & Restaurants</Link>
                    <Link to="/Gaming" onClick={closeMenu}>Gaming</Link>
                    <Link to="/Healthcare" onClick={closeMenu}>Healthcare</Link>
                    <Link to="/MediaAndPublication" onClick={closeMenu}>Media & Publication</Link>
                    <Link to="/Startups" onClick={closeMenu}>Startups</Link>
                    <Link to="/TourAndTravel" onClick={closeMenu}>Tour & Travel</Link>
                    <Link to="/RealState" onClick={closeMenu}>Real Estate</Link>
                </div>
                )}
                
                    <Link to="/Career" className="active" onClick={closeMenu}>Career</Link>
                    <Link to="/HireDeveloper" onClick={closeMenu}>Hire Developer</Link>
                    <Link to="/Contact" className="contact-btn" onClick={closeMenu}>Book a Call</Link>
            </div>
            <div className="hamburger-container">
                <a href="tel:7021247809" className="call-icon" title="Call">📞</a>
                
                <div className="hamburger" onClick={toggleMenu}>☰</div>
            </div>
        </div>
    );
};

export default Navbar;
