import React, { useState } from "react";

const PageSixWeb = () => {
  const styles = {
    body: {
      fontFamily: "'Roboto', sans-serif",
      margin: 0,
      padding: 0,
      backgroundColor: "#000000",
      color: "#ffffff",
    },
    container: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
      alignItems: "flex-start",
      padding: "20px",
      maxWidth: "1200px",
      margin: "5% auto",
      gap: "20px",
    },
    leftPanel: {
      flex: "1 1 250px",
      minWidth: "250px",
      padding: "10px",
      backgroundColor: "#1a1a1a",
      borderRadius: "8px",
    },
    rightPanel: {
      flex: "2 1 600px",
      minWidth: "300px",
      padding: "20px",
      backgroundColor: "#1a1a1a",
      borderRadius: "8px",
    },
    panelItem: (active) => ({
      backgroundColor: active ? "#f97316" : "#2c2c2c",
      color: active ? "#ffffff" : "#b0b0b0",
      padding: "15px 20px",
      marginBottom: "10px",
      borderRadius: "5px",
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      transition: "background-color 0.3s, color 0.3s",
    }),
    icon: {
      marginRight: "10px",
    },
    header: {
      textAlign: "center",
      marginBottom: "50px",
      margin:"50px",
    },
    headerH1: {
      fontSize: "2.5em",
      margin: "10px 0",
    },
    headerP: {
      color: "#f97316",
      fontWeight: "bold",
      fontSize: "1.2em",
      margin: "0",
    },
    content: {
      marginBottom: "20px",
    },
    contentHeading: {
      color: "#f97316",
      fontSize: "1.8em",
      margin: "10px 0",
    },
    contentParagraph: {
      fontSize: "1em",
      lineHeight: "1.5",
      margin: "10px 0",
    },
    list: {
      listStyle: "none",
      padding: 0,
      marginTop: "10px",
    },
    listItem: {
      display: "flex",
      alignItems: "center",
      marginBottom: "10px",
    },
    listItemIcon: {
      color: "#f97316",
      marginRight: "8px",
    },
    imageContainer: {
      textAlign: "center",
      marginTop: "20px",
    },
    image: {
      width: "100%", // Responsive width
      height: "auto", // Maintain aspect ratio
      borderRadius: "10px",
      objectFit: "cover", // Crop the image to fit the dimensions
      objectPosition: "center", // Focus on the center of the image
    },
    '@media (max-width: 768px)': {
      container: {
        flexDirection: "column",
      },
      rightPanel: {
        marginTop: "20px",
      },
    },
  };

  const sections = [
    {
      title: "SEO Optimisation",
      description:
        "We ensure that your website is optimised for search engines right from the start. We optimise meta tags, headers, content, and images to improve search engine visibility and enhance website speed and mobile responsiveness for better search rankings.",
      points: [
        "Keyword Research",
        "Technical SEO",
        "On-Page SEO",
        "Page Speed Optimisation",
      ],
      image: "https://images.pexels.com/photos/270637/pexels-photo-270637.jpeg?auto=compress&cs=tinysrgb&w=600",
    },
    {
      title: "Security",
      description:
        "Our comprehensive security measures protect your website from threats and vulnerabilities. From HTTPS implementation to regular audits, we make sure your online presence is safe and reliable.",
      points: [
        "HTTPS Implementation",
        "Regular Security Audits",
        "Malware Protection",
        "Firewall Setup",
      ],
      image: "https://images.pexels.com/photos/60504/security-protection-anti-virus-software-60504.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    },
    {
      title: "E-Commerce Integration",
      description:
        "We streamline your e-commerce operations by integrating robust platforms and tools that enhance your online store. From product management to payment gateways, we handle it all.",
      points: [
        "Platform Integration",
        "Payment Gateways",
        "Product Management",
        "Customer Analytics",
      ],
      image: "https://images.pexels.com/photos/230544/pexels-photo-230544.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    },
    {
      title: "Conversion Rate Optimisation",
      description:
        "Increase your website's effectiveness with our conversion rate optimisation services. We focus on user behavior, design improvements, and effective call-to-actions to maximize your ROI.",
      points: [
        "A/B Testing",
        "User Behavior Analysis",
        "Optimised Landing Pages",
        "Effective Call-to-Actions",
      ],
      image: "https://images.pexels.com/photos/6893921/pexels-photo-6893921.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    },
  ];

  const [activeSection, setActiveSection] = useState(sections[0]);

  return (
    <div style={styles.body}>
      <div style={styles.header}>
        <p style={styles.headerP}>WE ARE THE EXPERTS</p>
        <h1 style={styles.headerH1}>We Go Beyond the Basics</h1>
      </div>
      <div style={styles.container}>
        {/* Left Panel */}
        <div style={styles.leftPanel}>
          {sections.map((section) => (
            <div
              key={section.title}
              style={styles.panelItem(activeSection.title === section.title)}
              onClick={() => setActiveSection(section)}
            >
              <i className="fas fa-check-circle" style={styles.icon}></i>
              <span>{section.title}</span>
            </div>
          ))}
        </div>

        {/* Right Panel */}
        <div style={styles.rightPanel}>
          <div style={styles.content}>
            <h2 style={styles.contentHeading}>TAILORED EXCELLENCE</h2>
            <h2 style={styles.contentHeading}>{activeSection.title}</h2>
            <p style={styles.contentParagraph}>{activeSection.description}</p>
            <ul style={styles.list}>
              {activeSection.points.map((point, index) => (
                <li key={index} style={styles.listItem}>
                  <i
                    className="fas fa-check-circle"
                    style={styles.listItemIcon}
                  ></i>
                  {point}
                </li>
              ))}
            </ul>
          </div>
          <div style={styles.imageContainer}>
            <img
              src={activeSection.image}
              alt={activeSection.title}
              style={styles.image}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageSixWeb;
