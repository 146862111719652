import React, { useState, useEffect } from "react";

// Inline CSS styles
const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "2rem",
    backgroundColor: "#000000",
    color: "white",
  },
  header: {
    textAlign: "center",
    fontSize: "2rem",
    fontWeight: "bold",
    marginBottom: "0.5rem",
  },
  subHeader: {
    textAlign: "center",
    fontSize: "1rem",
    marginBottom: "2rem",
    color: "#aaaaaa",
  },
  stepsContainer: {
    display: "flex",
    justifyContent: "space-around",
    flexWrap: "wrap",
    width: "100%",
    maxWidth: "1200px",
  },
  stepBox: (isVisible, isHovered) => ({
    flex: "1 1 20%",
    margin: "1rem",
    padding: "2rem",
    backgroundColor: "#1c1c1c",
    borderRadius: "10px",
    textAlign: "center",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
    cursor: "pointer",
    minWidth: "200px",
    opacity: isVisible ? 1 : 0,
    transform: isVisible
      ? `translateY(0) scale(${isHovered ? 1.05 : 1})`
      : "translateY(20px)",
    transition: "transform 0.5s ease-out, box-shadow 0.3s ease",
  }),
  stepNumber: {
    fontSize: "3rem",
    fontWeight: "bold",
    color: "#888888",
  },
  stepDescription: {
    fontSize: "1rem",
    color: "#dddddd",
    marginBottom: "1rem",
  },
  stepLine: (color) => ({
    height: "4px",
    backgroundColor: color,
    width: "100%",
    marginTop: "0.5rem",
  }),
};

const steps = [
  { number: "01", description: "Tell us your requirements", color: "#FF6B6B" },
  { number: "02", description: "We shortlist the best talents", color: "#FFD93D" },
  { number: "03", description: "Take interview of the developer team", color: "#4DD0E1" },
  { number: "04", description: "Hire the best developers", color: "#BA68C8" },
];

const Devfive = () => {
  const [visibleSteps, setVisibleSteps] = useState([false, false, false, false]);
  const [hoveredStep, setHoveredStep] = useState(null);

  useEffect(() => {
    // Show each step one by one with a delay
    steps.forEach((_, index) => {
      setTimeout(() => {
        setVisibleSteps((prev) => {
          const newState = [...prev];
          newState[index] = true;
          return newState;
        });
      }, index * 300); // Delay each step by 300ms
    });
  }, []);

  return (
    <div style={styles.container}>
      <h2 style={styles.header}>Hire Your Next Expert Developers in 4 Steps</h2>
      <p style={styles.subHeader}>
        Hiring an expert developer from Kotibox is simple. Follow 4 easy steps to employ a team for your next digital project.
      </p>
      <div style={styles.stepsContainer}>
        {steps.map((step, index) => (
          <div
            key={index}
            style={styles.stepBox(visibleSteps[index], hoveredStep === index)}
            onMouseEnter={() => setHoveredStep(index)}
            onMouseLeave={() => setHoveredStep(null)}
          >
            <div style={styles.stepNumber}>{step.number}</div>
            <p style={styles.stepDescription}>{step.description}</p>
            <div style={styles.stepLine(step.color)} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Devfive;
