import React, { useEffect, useRef } from 'react';
// Import images from the local directory
import Appone from '../../imgvdo/Appone.gif';
import Apptwo from '../../imgvdo/Apptwo.gif';
import Appthree from '../../imgvdo/Appthree.gif';
import AppFour from '../../imgvdo/AppFour.gif';
import AppFive from '../../imgvdo/AppFive.gif';
import AppSix from '../../imgvdo/AppSix.gif';
import AppSeven from '../../imgvdo/AppSeven.gif';
import AppEight from '../../imgvdo/AppEight.gif';
// import img9 from '../assets/images/IMG-7300.jpg';

const PageSixApp = () => {
  const galleryRef = useRef(null);

  const styles = {
    container: {
      textAlign: 'center',
      padding: '50px 20px',
      color: '#ffffff',
    },
    h1: {
      fontSize: '32px',
      margin: '10px 0',
      fontWeight: 'bold',
    },
    h1Span: {
      color: '#ff6600', // Accent color
    },
    p: {
      fontSize: '18px',
      margin: '10px 0 40px',
      lineHeight: '1.6',
      color: '#999', // Medium gray for paragraph text
    },
    galleryWrapper: {
      overflow: 'hidden',
      width: '100%',
      position: 'relative',
      backgroundColor: '#f9f9f9',
      borderRadius: '10px',
      boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
    },
    gallery: {
      display: 'flex',
      width: 'max-content',
      animation: 'scroll 30s linear infinite',
    },
    img: {
      width: '400px',  // Adjusted size
      height: '300px', // Adjusted size
      objectFit: 'cover',
      borderRadius: '10px',
      margin: '0 10px',
      transition: 'transform 0.5s ease, filter 0.5s ease',
    },
  };

  const images = [Appone,Apptwo,Appthree,AppFour,AppFive,AppSix,AppSeven,AppEight];

  // Dynamically duplicate images
  const duplicatedImages = [...images, ];

  // Function to pause animation
  const pauseAnimation = () => {
    if (galleryRef.current) {
      galleryRef.current.style.animationPlayState = 'paused';
    }
  };

  // Function to resume animation
  const resumeAnimation = () => {
    if (galleryRef.current) {
      galleryRef.current.style.animationPlayState = 'running';
    }
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.h1}>
        <span style={styles.h1Span}> Do You Know?</span>
        <br />
        What is the process for app development?
      </h1>
      <p style={styles.p}>Book Your personalized App </p>
      <div style={styles.galleryWrapper}>
        <div style={styles.gallery} ref={galleryRef}>
          {duplicatedImages.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Gallery Image ${index + 1}`}
              style={styles.img}
              onMouseEnter={(e) => {
                pauseAnimation();
                e.target.style.transform = 'scale(1.2)';
                e.target.style.filter = 'brightness(1.2)';
              }}
              onMouseLeave={(e) => {
                resumeAnimation();
                e.target.style.transform = 'scale(1)';
                e.target.style.filter = 'brightness(1)';
              }}
            />
          ))}
        </div>
      </div>
      <style>
        {`
          @keyframes scroll {
            from {
              transform: translateX(0);
            }
            to {
              transform: translateX(-50%);
            }
          }
        `}
      </style>
    </div>
  );
};

export default PageSixApp;
