import React, { useState, useEffect } from 'react';
import Second from './Second';
import Four from './Four';
import Five from './Five';
import Six from './Six';
import Eight from './Eight';

const FirstBank = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [result, setResult] = useState("");

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const onSubmit = async (event) => {
    event.preventDefault();
    setResult("Sending....");
    const formData = new FormData(event.target);

    formData.append("access_key", "45ea370d-181b-47ff-bfa8-5f2650b8ab71");

    const response = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData
    });

    const data = await response.json();

    if (data.success) {
      setResult("Form Submitted Successfully");
      event.target.reset();
    } else {
      console.log("Error", data);
      setResult(data.message);
    }
  };

  return (
    <>
      {/* Background Video */}
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          overflow: 'hidden',
          zIndex: -1,
        }}
      >
        <video
          autoPlay
          loop
          muted
          playsInline
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
        >
          <source
            src="https://videos.pexels.com/video-files/3135907/3135907-hd_1920_1080_25fps.mp4" // Replace with your video URL
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </div>

      {/* Content */}
      <div
        style={{
          margin: 0,
          fontFamily: "'Roboto', sans-serif",
          color: '#fff',
          minHeight: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '20px',
          boxSizing: 'border-box',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: windowWidth < 768 ? 'column' : 'row',
            alignItems: 'flex-start',
            width: '100%',
            maxWidth: '1200px',
            padding: '20px',
            backgroundColor: 'rgba(28, 28, 28, 0.85)',
            borderRadius: '15px',
            boxShadow: '0 8px 16px rgba(0, 0, 0, 0.3)',
            gap: '30px',
          }}
        >
          {/* Text Section */}
          <div
            style={{
              flex: 1,
              maxWidth: windowWidth < 768 ? '100%' : '60%',
              padding: '0 20px',
            }}
          >
            <h2 style={{ fontSize: '28px', color: '#f57c00', marginBottom: '10px' }}>
              Food & Restaurants
            </h2>
            <h1 style={{ fontSize: '42px', margin: '10px 0', lineHeight: '1.2' }}>
              Food & Restaurants Development Services
            </h1>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: windowWidth < 768 ? '1fr' : 'repeat(3, 1fr)',
                gap: '20px',
                margin: '30px 0',
              }}
            >
             {[ 
                { icon: 'fa-laptop-code', text: 'Online Ordering Systems' },
                { icon: 'fa-chalkboard-teacher', text: 'Menu Management' },
                { icon: 'fa-mobile-alt', text: 'Customer Loyalty Programs' },
                { icon: 'fa-video', text: 'Payment Gateway Integration' },
                { icon: 'fa-file-alt', text: 'Reservation Systems' },
                { icon: 'fa-gamepad', text: 'Inventory Management' },
              ].map((service, index) => (
                <div
                  key={index}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    backgroundColor: '#333',
                    borderRadius: '10px',
                    padding: '20px',
                    textAlign: 'center',
                    color: '#ddd',
                    transition: 'transform 0.3s, color 0.3s',
                    cursor: 'pointer',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
                  }}
                  onMouseEnter={(e) => {
                    e.currentTarget.style.color = '#f57c00';
                    e.currentTarget.style.transform = 'translateY(-5px)';
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.color = '#ddd';
                    e.currentTarget.style.transform = 'translateY(0)';
                  }}
                >
                  <i
                    className={`fas ${service.icon}`}
                    style={{
                      fontSize: '40px',
                      marginBottom: '15px',
                      color: '#f57c00',
                    }}
                  ></i>
                  <span style={{ fontSize: '18px', fontWeight: 'bold' }}>
                    {service.text}
                  </span>
                </div>
              ))}
            </div>
          </div>

          {/* Form Section */}
          <div
            style={{
              backgroundColor: 'rgba(0, 0, 0, 0.85)',
              padding: '30px',
              borderRadius: '8px',
              maxWidth: windowWidth < 768 ? '100%' : '350px',
              width: '100%',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
              display: 'flex',
              flexDirection: 'column',
              gap: '15px',
            }}
          >
            <h2 style={{ color: '#f57c00', fontSize: '20px', marginBottom: '15px' }}>
              Book Free Consultation
            </h2>
            <p style={{ fontSize: '13px', marginBottom: '15px', color: '#ccc' }}>
              Fill Out The Form And Our Experts Will Contact You Within 24hrs.
            </p>
            <form onSubmit={onSubmit}>
              <div style={{ marginBottom: '15px' }}>
                <input
                  type="text"
                  name="name"
                  placeholder="Full Name*"
                  required
                  style={{
                    width: '100%',
                    padding: '10px',
                    border: '1px solid #444',
                    borderRadius: '5px',
                    fontSize: '14px',
                    backgroundColor: '#333',
                    color: '#eee',
                  }}
                />
              </div>
              <div style={{ marginBottom: '15px' }}>
                <input
                  type="email"
                  name="email"
                  placeholder="Email*"
                  required
                  style={{
                    width: '100%',
                    padding: '10px',
                    border: '1px solid #444',
                    borderRadius: '5px',
                    fontSize: '14px',
                    backgroundColor: '#333',
                    color: '#eee',
                  }}
                />
              </div>
              <div style={{ marginBottom: '15px' }}>
                <input
                  type="text"
                  name="phone"
                  placeholder="Phone Number*"
                  required
                  style={{
                    width: '100%',
                    padding: '10px',
                    border: '1px solid #444',
                    borderRadius: '5px',
                    fontSize: '14px',
                    backgroundColor: '#333',
                    color: '#eee',
                  }}
                />
              </div>
              <div style={{ marginBottom: '15px' }}>
                <input
                  type="text"
                  name="company"
                  placeholder="Company Name"
                  style={{
                    width: '100%',
                    padding: '10px',
                    border: '1px solid #444',
                    borderRadius: '5px',
                    fontSize: '14px',
                    backgroundColor: '#333',
                    color: '#eee',
                  }}
                />
              </div>
              <div style={{ marginBottom: '15px' }}>
                <textarea
                  name="message"
                  placeholder="Your Requirement*"
                  required
                  style={{
                    width: '100%',
                    padding: '10px',
                    border: '1px solid #444',
                    borderRadius: '5px',
                    fontSize: '14px',
                    backgroundColor: '#333',
                    color: '#eee',
                    resize: 'none',
                    height: '100px',
                  }}
                ></textarea>
              </div>
              <button
                type="submit"
                style={{
                  backgroundColor: '#f57c00',
                  color: '#fff',
                  padding: '12px',
                  border: 'none',
                  borderRadius: '5px',
                  fontSize: '14px',
                  cursor: 'pointer',
                  width: '100%',
                  transition: 'background 0.3s',
                }}
                onMouseOver={(e) => (e.target.style.backgroundColor = '#e67e22')}
                onMouseOut={(e) => (e.target.style.backgroundColor = '#f57c00')}
              >
                Submit Your Requirement!
              </button>
            </form>
            <span>{result}</span>
          </div>
        </div>
      </div>
      <Six />
      <Second />
      <Five />
      <Four />
      <Eight />
    </>
  );
};

export default FirstBank;
