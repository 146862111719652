import React from 'react';

const PageFiveE = () => {
  const styles = {
    body: {
      fontFamily: "'Roboto', sans-serif",
      backgroundColor: '#121212', // Dark mode background
      color: '#ffffff',
      padding: 0,
      margin: 0,
    },
    container: {
      padding: '50px 15px',
    },
    headerSpan: {
      color: '#f97316',
      fontSize: '14px',
      fontWeight: 700,
      textAlign: 'center',
      display: 'block',
      marginBottom: '10px',
    },
    headerH1: {
      fontSize: '36px',
      fontWeight: 700,
      lineHeight: 1.3,
      textAlign: 'center',
      marginBottom: '30px',
    },
    serviceCard: {
      backgroundColor: '#1e1e1e', // Dark card background
      borderRadius: '12px',
      padding: '20px',
      boxShadow: '0 6px 15px rgba(0, 0, 0, 0.3)',
      transition: 'transform 0.3s ease, box-shadow 0.3s ease',
      margin: '0 10%', // 10% margin on left and right
      maxWidth: '80%', // Restrict card size
    },
    serviceCardHover: {
      transform: 'translateY(-10px)',
      boxShadow: '0 8px 20px rgba(0, 0, 0, 0.5)',
    },
    icon: {
      fontSize: '40px',
      color: '#f97316',
    },
    serviceCardH3: {
      fontSize: '20px',
      fontWeight: 700,
      margin: '20px 0 10px',
      textAlign: 'center',
      color: '#ffffff',
    },
    serviceCardP: {
      fontSize: '14px',
      lineHeight: 1.6,
      color: '#b0b0b0',
      textAlign: 'justify',
    },
    highlightIcon: {
      fontSize: '14px',
      color: '#f97316',
      marginRight: '10px',
    },
    highlightSpan: {
      backgroundColor: '#f97316',
      color: '#ffffff',
      padding: '5px 10px',
      borderRadius: '20px',
      fontSize: '12px',
    },
    arrowIcon: {
      fontSize: '20px',
      color: '#f97316',
    },
  };

  const serviceData = [
    {
        icon: 'fas fa-store',
        title: 'E-Commerce Store Development',
        description:
          'Launch a fully customized and feature-rich e-commerce store that provides a seamless shopping experience. We design and develop online stores with user-friendly interfaces, fast loading times, and secure payment gateways.',
        highlights: [
          'Custom Store Design',
          'Mobile-Friendly Layouts',
          'Secure Payment Gateways',
          'Optimized User Experience',
        ],
      },
      {
        icon: 'fas fa-cogs',
        title: 'E-Commerce Platform Integration',
        description:
          'Integrate your e-commerce store with leading platforms and third-party tools. Whether it’s ERP systems, inventory management, or customer support tools, we ensure smooth integration to streamline operations and boost sales.',
        highlights: [
          'Seamless Platform Integration',
          'Automated Inventory Management',
          'Third-Party Tool Integration',
          'Optimized Workflow Automation',
        ],
      },
      {
        icon: 'fas fa-search',
        title: 'E-Commerce SEO',
        description:
          'Increase your store’s visibility and drive organic traffic with our e-commerce SEO services. We optimize product pages, metadata, and content to improve search engine rankings and attract more customers.',
        highlights: [
          'Product Page Optimization',
          'Keyword Research and Targeting',
          'Mobile SEO and Optimization',
          'Improved Search Engine Rankings',
        ],
      },
      {
        icon: 'fas fa-shopping-cart',
        title: 'Shopping Cart and Checkout Optimization',
        description:
          'Enhance your customers’ shopping experience with optimized cart and checkout processes. We focus on reducing cart abandonment, streamlining payment options, and ensuring a secure, fast, and seamless transaction experience.',
        highlights: [
          'Fast and Easy Checkout Process',
          'Multiple Payment Options',
          'Secure Shopping Cart',
          'Reduced Cart Abandonment',
        ],
      },
      {
        icon: 'fas fa-gift',
        title: 'Product Catalog Management',
        description:
          'Maintain a well-organized product catalog with our product management services. We help you categorize, manage, and update products efficiently, ensuring a smooth browsing experience for your customers.',
        highlights: [
          'Product Categorization and Management',
          'Custom Product Attributes',
          'Inventory Management Solutions',
          'Seamless Product Updates',
        ],
      },
      {
        icon: 'fas fa-rocket',
        title: 'E-Commerce Growth and Marketing',
        description:
          'Boost sales and grow your e-commerce business with targeted marketing strategies. We develop customized marketing campaigns, including social media advertising, email marketing, and paid search ads to attract and convert customers.',
        highlights: [
          'Social Media Campaigns',
          'Email Marketing Campaigns',
          'Paid Search Advertising',
          'Customer Retargeting',
        ],
      },
      {
        icon: 'fas fa-mobile-alt',
        title: 'Mobile E-Commerce Solutions',
        description:
          'Reach customers on their mobile devices with responsive mobile e-commerce solutions. We ensure your store is fully optimized for mobile browsing and provide app development for seamless shopping experiences on smartphones and tablets.',
        highlights: [
          'Mobile-Responsive Design',
          'Mobile App Development',
          'Push Notification Campaigns',
          'Location-Based Targeting',
        ],
      },
      {
        icon: 'fas fa-truck',
        title: 'E-Commerce Logistics and Shipping Solutions',
        description:
          'Optimize your shipping and logistics processes to offer a smooth delivery experience for your customers. We integrate advanced shipping solutions, including real-time tracking, multiple carriers, and global shipping options.',
        highlights: [
          'Real-Time Shipping Tracking',
          'Multiple Carrier Integration',
          'Global Shipping Solutions',
          'Order Fulfillment Automation',
        ],
      },
      {
        icon: 'fas fa-headset',
        title: 'E-Commerce Customer Support',
        description:
          'Enhance customer satisfaction with our comprehensive customer support solutions. From live chat support to helpdesk integration, we ensure that your customers get timely assistance, boosting retention and trust.',
        highlights: [
          '24/7 Customer Support',
          'Live Chat Integration',
          'Helpdesk System Setup',
          'Automated Customer Service',
        ],
      },
     
      
      
  ];

  return (
    <div style={styles.body}>
      <div style={styles.container}>
        <div>
          <span style={styles.headerSpan}>SERVICES</span>
          <h1 style={styles.headerH1}>
            Our Expertise In E-Commerce Services
          </h1>
        </div>
        <div className="row g-4">
          {serviceData.map((service, index) => (
            <div className="col-12 col-md-6 col-lg-4" key={index}>
              <div
                style={styles.serviceCard}
                onMouseOver={(e) =>
                  Object.assign(e.currentTarget.style, styles.serviceCardHover)
                }
                onMouseOut={(e) =>
                  Object.assign(e.currentTarget.style, {
                    transform: 'translateY(0)',
                    boxShadow: '0 6px 15px rgba(0, 0, 0, 0.3)',
                  })
                }
              >
                <div className="text-center mb-3" style={styles.icon}>
                  <i className={service.icon}></i>
                </div>
                <h3 style={styles.serviceCardH3}>{service.title}</h3>
                <p style={styles.serviceCardP}>{service.description}</p>
                <div>
                  {service.highlights.map((highlight, idx) => (
                    <div key={idx} className="d-flex align-items-center mb-2">
                      <i
                        className="fas fa-check"
                        style={styles.highlightIcon}
                      ></i>
                      <span style={styles.highlightSpan}>{highlight}</span>
                    </div>
                  ))}
                </div>
                <div className="text-end">
                  <i className="fas fa-arrow-right" style={styles.arrowIcon}></i>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PageFiveE;
