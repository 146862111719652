import React from 'react';

const testimonials = [
  {
    category: 'Harrison',
    title: 'The app is visually appealing, performs seamlessly across different devices, and is free from bugs. Their attention to detail ensured that the app was not only functional but also engaging and intuitive for users.',
    location: 'UK',
    icon: '👍',
    technology: 'ALL',
  },
  {
    category: 'Harry Bidwell',
    title: 'Kotibox Team demonstrated a deep understanding of ERP systems and tailored their approach to meet our specific needs. Their technical expertise was evident in the robust and scalable solution they provided.',
    location: 'USA',
    icon: '😳',
    technology: 'ALL',
  },
  {
    category: 'Sylvie',
    title: 'The team at Kotibox Global Technologies was incredibly responsive and communicative throughout the entire process. They delivered a visually stunning website that was highly functional and user-friendly.',
    location: 'UK',
    icon: '😎',
    technology: 'ALL',
  },
  {
    category: 'Amelia Robertson',
    title: 'Working with Kotibox was a delight. Their agile development process and regular updates made me feel involved every step of the way. I couldn’t be happier with the result!',
    location: 'Canada',
    icon: '🎉',
    technology: 'ReactJS',
  },
  {
    category: 'David Thompson',
    title: 'I was impressed by their deep understanding of the latest technologies and their ability to implement them seamlessly. The platform they built for us is scalable, secure, and highly efficient.',
    location: 'Australia',
    icon: '💻',
    technology: 'NodeJS',
  },
  {
    category: 'Sophia Lee',
    title: 'Kotibox transformed our outdated system into a modern and user-friendly solution. Their dedication and creativity ensured our project was a huge success!',
    location: 'Singapore',
    icon: '✨',
    technology: 'MERN',
  },
  {
    category: 'James Parker',
    title: 'The Kotibox team delivered an exceptional mobile app that not only meets our needs but exceeds our expectations. Their expertise in mobile development and user experience design is unmatched.',
    location: 'USA',
    icon: '📱',
    technology: 'React Native',
  },
  {
    category: 'Lily Evans',
    title: 'Kotibox helped us redesign our website, and the result was a modern, intuitive, and responsive site. Their approach was collaborative, and they truly understood our vision.',
    location: 'UK',
    icon: '🌐',
    technology: 'NextJS',
  },
  {
    category: 'John Doe',
    title: 'The team at Kotibox took our outdated platform and transformed it into a sleek, high-performing solution. Their attention to detail and focus on user experience made all the difference.',
    location: 'USA',
    icon: '🚀',
    technology: 'ReactJS',
  },
  {
    category: 'I thik Next will be You 😍',
    title: 'Lets Build you Dream',
    location: 'By Kotibox Global Technologies',
    
  },
];

const styles = {
  testimonialContainer: {
    backgroundColor: '#111',
    color: 'white',
    padding: '40px',
    textAlign: 'center',
  },
  testimonialTitle: {
    fontSize: '2.5rem',
    marginBottom: '10px',
    fontWeight: 'bold',
  },
  testimonialSubtitle: {
    fontSize: '1.2rem',
    marginBottom: '40px',
    color: '#bbb',
  },
  testimonialGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
    gap: '20px',
    justifyContent: 'center',
  },
  testimonialCard: {
    backgroundColor: '#222',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.3)',
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
    textAlign: 'left',
  },
  testimonialCategory: {
    fontSize: '1.1rem',
    fontWeight: 'bold',
    color: '#ff9900',
  },
  testimonialTitleText: {
    fontSize: '1rem',
    margin: '15px 0',
    color: 'white',
  },
  testimonialFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '10px',
  },
  footerLocation: {
    color: '#ccc',
  },
  footerIcon: {
    fontSize: '1.5rem',
  },
  footerTechnology: {
    color: '#fff',
    fontWeight: 'bold',
  },
};

const Testimonials = () => {
  return (
    <div style={styles.testimonialContainer}>
      <h1 style={styles.testimonialTitle}>
        Client <span style={{ color: 'red' }}>Success Stories</span>
      </h1>
      <h5 style={styles.testimonialSubtitle}>
        Grow your business with a team that wants to see you win
      </h5>
      <div style={styles.testimonialGrid}>
        {testimonials.map((testimonial, index) => (
          <div key={index} style={styles.testimonialCard}>
            <div style={styles.testimonialCategory}>{testimonial.category}</div>
            <div style={styles.testimonialTitleText}>{testimonial.title}</div>
            <div style={styles.testimonialFooter}>
              <span style={styles.footerLocation}>{testimonial.location}</span>
              <span style={styles.footerIcon}>{testimonial.icon}</span>
              <span style={styles.footerTechnology}>{testimonial.technology}</span>
            </div>
          </div>
        ))}
      </div>
      <style>{`
        .testimonial-card:hover {
          transform: translateY(-10px);
          box-shadow: 0 4px 16px rgba(0, 0, 0, 0.5);
        }
        @media (max-width: 768px) {
          h1 {
            font-size: 2rem;
          }
          h5 {
            font-size: 1rem;
          }
        }
        @media (max-width: 480px) {
          .testimonial-container {
            padding: 20px;
          }
          h1 {
            font-size: 1.5rem;
          }
          h5 {
            font-size: 0.9rem;
          }
        }
      `}</style>
    </div>
  );
};

export default Testimonials;
