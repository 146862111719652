// Importing React and dependencies
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const PageOneUI = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const fadeInElements = document.querySelectorAll('.fade-in');
    fadeInElements.forEach((el, index) => {
      setTimeout(() => {
        el.style.opacity = 1;
        el.style.transform = 'translateY(0)';
      }, index * 300);
    });
  }, []);

  return (
    <div style={{ fontFamily: 'Arial, sans-serif', margin: '0', padding: '0', color: '#fff', backgroundColor: '#121212' }}>
      {/* Header Section */}
      <header
        className="fade-in"
        style={{
          textAlign: 'center',
          padding: '50px 20px',
          backgroundColor: '#1a1a1a',
          opacity: 0,
          transform: 'translateY(-20px)',
          transition: 'opacity 1s, transform 1s',
        }}
      >
        <h1 style={{ fontSize: '3rem', fontWeight: 'bold', color: '#FFC107' }}>UI/UX & Videography Services</h1>
        <p style={{ fontSize: '1.5rem', color: '#E0E0E0' }}>Crafting Engaging Designs & Stunning Visuals</p>
      </header>

      {/* About Section */}
      <section style={{ padding: '50px 20px', display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '30px' }}>
        <div
          className="fade-in"
          style={{
            maxWidth: '600px',
            textAlign: 'justify',
            opacity: 0,
            transform: 'translateY(20px)',
            transition: 'opacity 1s, transform 1s',
          }}
        >
          <h2 style={{ color: '#FFC107', fontSize: '2rem', marginBottom: '20px' }}>Our Expertise in UI/UX</h2>
          <p style={{ fontSize: '1rem', lineHeight: '1.8' }}>
            Our UI/UX design services focus on creating intuitive and visually appealing interfaces. With a user-first approach, we ensure every design speaks volumes about your brand and enhances user experience. From wireframes to prototypes, we deliver excellence.
          </p>
        </div>
        <div
          className="fade-in"
          style={{
            maxWidth: '600px',
            textAlign: 'justify',
            opacity: 0,
            transform: 'translateY(20px)',
            transition: 'opacity 1s, transform 1s',
          }}
        >
          <h2 style={{ color: '#FFC107', fontSize: '2rem', marginBottom: '20px' }}>Professional Videography</h2>
          <p style={{ fontSize: '1rem', lineHeight: '1.8' }}>
            Captivating videography that tells your story. Our team of experts captures every detail with precision, ensuring high-quality visuals that resonate with your audience. From promotional videos to cinematic productions, we bring your vision to life.
          </p>
        </div>
      </section>

      {/* Creative Process Section */}
      <section style={{ backgroundColor: '#1a1a1a', padding: '50px 20px', textAlign: 'center' }}>
        <h2
          className="fade-in"
          style={{
            color: '#FFC107',
            fontSize: '2.5rem',
            marginBottom: '30px',
            opacity: 0,
            transform: 'translateY(20px)',
            transition: 'opacity 1s, transform 1s',
          }}
        >
          Our Creative Process
        </h2>
        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '20px' }}>
          {['Ideation', 'Design', 'Production', 'Delivery'].map((step, index) => (
            <div
              key={index}
              className="fade-in"
              style={{
                backgroundColor: '#212121',
                padding: '20px',
                borderRadius: '10px',
                width: '300px',
                opacity: 0,
                transform: 'translateY(20px)',
                transition: 'opacity 1s, transform 1s',
              }}
            >
              <h3 style={{ color: '#FFC107', fontSize: '1.5rem' }}>{`${index + 1}. ${step}`}</h3>
              <p style={{ color: '#E0E0E0', fontSize: '1rem' }}>
                {step === 'Ideation'
                  ? 'Brainstorming and conceptualizing unique ideas tailored to your needs.'
                  : step === 'Design'
                  ? 'Crafting visually compelling and user-friendly designs.'
                  : step === 'Production'
                  ? 'Transforming concepts into reality with precision and creativity.'
                  : 'Timely delivery of projects with exceptional quality assurance.'}
              </p>
            </div>
          ))}
        </div>
      </section>

      {/* Client Testimonials Section */}
      <section style={{ padding: '50px 20px', backgroundColor: '#121212', textAlign: 'center' }}>
        <h2
          className="fade-in"
          style={{
            color: '#FFC107',
            fontSize: '2.5rem',
            marginBottom: '20px',
            opacity: 0,
            transform: 'translateY(20px)',
            transition: 'opacity 1s, transform 1s',
          }}
        >
          What Our Clients Say
        </h2>
        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '30px' }}>
          {[
            'Their UI/UX designs transformed our website into a masterpiece. Highly recommended!',
            'The videography team captured every detail with excellence. Simply outstanding work!',
          ].map((quote, index) => (
            <div
              key={index}
              className="fade-in"
              style={{
                backgroundColor: '#212121',
                padding: '20px',
                borderRadius: '10px',
                maxWidth: '400px',
                opacity: 0,
                transform: 'translateY(20px)',
                transition: 'opacity 1s, transform 1s',
              }}
            >
              <p style={{ color: '#E0E0E0', fontSize: '1rem', fontStyle: 'italic' }}>{`"${quote}"`}</p>
              <h4 style={{ color: '#FFC107', marginTop: '10px' }}>{index === 0 ? '– John Doe' : '– Aishwarya Singh'}</h4>
            </div>
          ))}
        </div>
      </section>

      {/* Contact Section */}
      <section
        className="fade-in"
        style={{
          padding: '50px 20px',
          backgroundColor: '#121212',
          textAlign: 'center',
          opacity: 0,
          transform: 'translateY(20px)',
          transition: 'opacity 1s, transform 1s',
        }}
      >
        <h2 style={{ color: '#FFC107', fontSize: '2.5rem', marginBottom: '20px' }}>Let’s Work Together!</h2>
        <p style={{ color: '#E0E0E0', fontSize: '1.2rem', marginBottom: '30px' }}>
          Have a project in mind? Reach out to us, and let's bring your ideas to life.
        </p>
        <button
          style={{
            backgroundColor: '#FFC107',
            color: '#121212',
            padding: '10px 20px',
            fontSize: '1rem',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            transition: 'background-color 0.3s',
          }}
          onMouseEnter={(e) => (e.target.style.backgroundColor = '#FFD54F')}
          onMouseLeave={(e) => (e.target.style.backgroundColor = '#FFC107')}
          onClick={() => navigate('/Contact')}
        >
          Contact Us
        </button>
      </section>
    </div>
  );
};

export default PageOneUI;
