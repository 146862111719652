import React from 'react';

const PageFiveWeb = () => {
  const styles = {
    body: {
      fontFamily: "'Roboto', sans-serif",
      backgroundColor: '#121212', // Dark mode background
      color: '#ffffff',
      padding: 0,
      margin: 0,
    },
    container: {
      padding: '50px 15px',
    },
    headerSpan: {
      color: '#f97316',
      fontSize: '14px',
      fontWeight: 700,
      textAlign: 'center',
      display: 'block',
      marginBottom: '10px',
    },
    headerH1: {
      fontSize: '36px',
      fontWeight: 700,
      lineHeight: 1.3,
      textAlign: 'center',
      marginBottom: '30px',
    },
    serviceCard: {
      backgroundColor: '#1e1e1e', // Dark card background
      borderRadius: '12px',
      padding: '20px',
      boxShadow: '0 6px 15px rgba(0, 0, 0, 0.3)',
      transition: 'transform 0.3s ease, box-shadow 0.3s ease',
      margin: '0 10%', // 10% margin on left and right
      maxWidth: '80%', // Restrict card size
    },
    serviceCardHover: {
      transform: 'translateY(-10px)',
      boxShadow: '0 8px 20px rgba(0, 0, 0, 0.5)',
    },
    icon: {
      fontSize: '40px',
      color: '#f97316',
    },
    serviceCardH3: {
      fontSize: '20px',
      fontWeight: 700,
      margin: '20px 0 10px',
      textAlign: 'center',
      color: '#ffffff',
    },
    serviceCardP: {
      fontSize: '14px',
      lineHeight: 1.6,
      color: '#b0b0b0',
      textAlign: 'justify',
    },
    highlightIcon: {
      fontSize: '14px',
      color: '#f97316',
      marginRight: '10px',
    },
    highlightSpan: {
      backgroundColor: '#f97316',
      color: '#ffffff',
      padding: '5px 10px',
      borderRadius: '20px',
      fontSize: '12px',
    },
    arrowIcon: {
      fontSize: '20px',
      color: '#f97316',
    },
  };

  const serviceData = [
    {
        icon: 'fas fa-laptop',
        title: 'Custom Website Development',
        description:
          'Create a unique and engaging online presence with our custom website development services. We craft tailored websites that are visually appealing, user-friendly, and optimized for performance across all devices and browsers.',
        highlights: [
          'Tailored Web Design',
          'Responsive and Mobile-Optimized',
          'High-Performance and Fast Loading',
          'SEO-Friendly Structure',
        ],
      },
      {
        icon: 'fas fa-desktop',
        title: 'Responsive Web Design',
        description:
          'Ensure your website looks great and functions smoothly on any device with responsive web design. We create adaptable layouts that adjust to all screen sizes, from desktops to smartphones, providing an optimal user experience.',
        highlights: [
          'Mobile-Friendly Designs',
          'Adaptive Layouts for All Devices',
          'Optimized User Interface (UI)',
          'Cross-Browser Compatibility',
        ],
      },
      {
        icon: 'fas fa-code',
        title: 'Front-End Development',
        description:
          'Build interactive and visually appealing front-end user interfaces with our front-end development expertise. We use the latest technologies like HTML5, CSS3, JavaScript, and modern frameworks such as React.js to create engaging websites.',
        highlights: [
          'Interactive User Interfaces',
          'Smooth Animations and Transitions',
          'Fast Load Times',
          'Advanced Front-End Frameworks (React, Angular, Vue)',
        ],
      },
      {
        icon: 'fas fa-server',
        title: 'Back-End Development',
        description:
          'Develop a strong back-end that powers your website with robust functionality, secure databases, and server-side processes. We use technologies like Node.js, Python, PHP, and Ruby on Rails to deliver high-performance web solutions.',
        highlights: [
          'Database Management and Integration',
          'Secure and Scalable Back-End Systems',
          'RESTful API Development',
          'Real-Time Data Processing',
        ],
      },
      {
        icon: 'fas fa-paint-brush',
        title: 'UI/UX Design',
        description:
          'Craft visually stunning and intuitive user interfaces with a focus on user experience (UX). Our UI/UX design services ensure that your website is not only aesthetically pleasing but also easy to navigate and enjoyable to use.',
        highlights: [
          'User-Centered Design',
          'Intuitive Navigation',
          'Visually Appealing Interfaces',
          'Enhanced User Engagement',
        ],
      },
      {
        icon: 'fas fa-search',
        title: 'SEO-Optimized Website Development',
        description:
          'Increase your website’s visibility on search engines with SEO-optimized web development. We implement best practices for on-page SEO, including fast load times, clean code, keyword optimization, and mobile-friendly design.',
        highlights: [
          'SEO-Friendly Structure and Code',
          'Faster Load Times for Better Ranking',
          'Keyword Optimization',
          'Google Search Engine Compatibility',
        ],
      },
      {
        icon: 'fas fa-lock',
        title: 'Website Security and Maintenance',
        description:
          'Ensure your website is secure with advanced security protocols and regular maintenance. We implement SSL certificates, data encryption, and regular updates to protect your website from threats and ensure optimal performance.',
        highlights: [
          'SSL Certificate Integration',
          'Regular Security Patches and Updates',
          'Data Encryption',
          'Backup and Disaster Recovery',
        ],
      },
      {
        icon: 'fas fa-cloud',
        title: 'Cloud-Based Website Solutions',
        description:
          'Take your website to the cloud for increased scalability and reliability. We provide cloud hosting, cloud storage, and cloud integration to ensure your website can handle high traffic and grow with your business.',
        highlights: [
          'Cloud Hosting and Storage',
          'Scalable Web Solutions',
          'High Availability and Performance',
          'Easy Integration with Cloud Services',
        ],
      },
      {
        icon: 'fas fa-cart-plus',
        title: 'E-commerce Website Development',
        description:
          'Launch your online store with our custom e-commerce website development services. We design secure, user-friendly, and visually appealing e-commerce websites using platforms like Shopify, WooCommerce, and Magento.',
        highlights: [
          'User-Friendly Shopping Experience',
          'Secure Payment Gateway Integration',
          'Customizable Product Catalogs',
          'Inventory and Order Management',
        ],
      },
      {
        icon: 'fas fa-cogs',
        title: 'Website Redesign and Revamping',
        description:
          'Give your website a fresh, modern look with our website redesign services. Whether you need a simple visual update or a complete revamp of your website’s structure, we provide solutions to improve aesthetics, functionality, and user experience.',
        highlights: [
          'Modern and Fresh Design',
          'Improved User Experience (UX)',
          'Enhanced Website Performance',
          'Optimized for Mobile Devices',
        ],
      },
      {
        icon: 'fas fa-chart-line',
        title: 'Digital Marketing Solutions',
        description:
          'Boost your online presence with our digital marketing services. We offer SEO, social media marketing, PPC campaigns, and content marketing to help you reach a broader audience and drive more traffic to your website.',
        highlights: [
          'Search Engine Optimization (SEO)',
          'Social Media Marketing Strategies',
          'Pay-Per-Click (PPC) Campaigns',
          'Content Marketing and Branding',
        ],
      },
      {
        icon: 'fas fa-users',
        title: 'Customer Support and Engagement',
        description:
          'Provide top-notch customer support with our tailored solutions. We design chatbots, live chat integrations, and support ticket systems to ensure your customers receive quick and efficient assistance, enhancing their experience.',
        highlights: [
          '24/7 Live Chat Support',
          'Chatbot Integration',
          'Ticket Management Systems',
          'Customer Satisfaction Tracking',
        ],
      },
      
      
  ];

  return (
    <div style={styles.body}>
      <div style={styles.container}>
        <div>
          <span style={styles.headerSpan}>SERVICES</span>
          <h1 style={styles.headerH1}>
            Our Expertise In Website Development
          </h1>
        </div>
        <div className="row g-4">
          {serviceData.map((service, index) => (
            <div className="col-12 col-md-6 col-lg-4" key={index}>
              <div
                style={styles.serviceCard}
                onMouseOver={(e) =>
                  Object.assign(e.currentTarget.style, styles.serviceCardHover)
                }
                onMouseOut={(e) =>
                  Object.assign(e.currentTarget.style, {
                    transform: 'translateY(0)',
                    boxShadow: '0 6px 15px rgba(0, 0, 0, 0.3)',
                  })
                }
              >
                <div className="text-center mb-3" style={styles.icon}>
                  <i className={service.icon}></i>
                </div>
                <h3 style={styles.serviceCardH3}>{service.title}</h3>
                <p style={styles.serviceCardP}>{service.description}</p>
                <div>
                  {service.highlights.map((highlight, idx) => (
                    <div key={idx} className="d-flex align-items-center mb-2">
                      <i
                        className="fas fa-check"
                        style={styles.highlightIcon}
                      ></i>
                      <span style={styles.highlightSpan}>{highlight}</span>
                    </div>
                  ))}
                </div>
                <div className="text-end">
                  <i className="fas fa-arrow-right" style={styles.arrowIcon}></i>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PageFiveWeb;
