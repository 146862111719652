import React, { useState } from 'react';
import { FaInstagram, FaLinkedin } from 'react-icons/fa'; // Importing react-icons
import kotiboxlogowhite from '../imgvdo/kotiboxlogowhite.png';

const CustomFooter = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [result, setResult] = useState("");

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    setResult("Sending...");
    const formData = new FormData(event.target);

    formData.append("access_key", "45ea370d-181b-47ff-bfa8-5f2650b8ab71");

    const response = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData,
    });

    const data = await response.json();

    if (data.success) {
      setResult("Form Submitted Successfully");
      event.target.reset();
    } else {
      console.log("Error", data);
      setResult(data.message);
    }
  };

  const footerStyle = {
    backgroundColor: '#000000',
    color: 'white',
    padding: '40px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    fontSize: '14px',
    fontFamily: 'Arial, sans-serif',
    animation: 'fadeInUp 1s ease-in-out',
    position: 'relative',
    gap: '20px',
  };

  const columnStyle = {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    flex: '1',
    minWidth: '200px', // Ensures columns shrink properly
    margin: '10px 0',
  };

  const lowerContentStyle = {
    flex: '1',
    minWidth: '300px', // Adjust minimum size for the lower content
    marginTop: '20px',
  };

  const headingStyle = {
    fontWeight: 'bold',
    marginBottom: '20px',
    fontSize: '18px',
    color: '#ffc107',
  };

  const linkStyle = {
    color: 'white',
    textDecoration: 'none',
    marginBottom: '8px',
    transition: 'color 0.3s',
    cursor: 'pointer',
  };

  const locationStyle = {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  };

  const indiaFlagContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    marginTop: '10px',
  };

  const indiaFlagStyle = {
    width: '60px',
    height: 'auto',
  };

  const logoStyle = {
    width: '50px',
    height: 'auto',
  };

  const projectEnquiryButtonStyle = {
    backgroundColor: '#ffc107',
    padding: '10px 20px',
    color: '#000',
    borderRadius: '25px',
    cursor: 'pointer',
    marginTop: '20px',
    fontWeight: 'bold',
    boxShadow: '0 4px 10px rgba(0,0,0,0.1)',
    transition: 'transform 0.3s, box-shadow 0.3s',
    textAlign: 'center',
    display: 'inline-block',
  };

  const contactDetailsStyle = {
    marginTop: '20px',
    color: '#ccc',
    fontSize: '13px',
    lineHeight: '1.6',
  };

  const socialMediaContainerStyle = {
    display: 'flex',
    gap: '15px',
    marginTop: '10px',
  };

  const socialIconStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '30px',
    height: '30px',
    backgroundColor: '#333',
    borderRadius: '50%',
    color: '#fff',
    fontSize: '16px',
    cursor: 'pointer',
    transition: 'transform 0.3s, background-color 0.3s',
  };

  const bottomFooterStyle = {
    backgroundColor: '#141414',
    padding: '15px',
    textAlign: 'center',
    color: '#ccc',
    fontSize: '12px',
    width: '100%',
  };

  const formPopupStyle = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 1000,
    width: '300px',
    backgroundColor: '#333',
    borderRadius: '10px',
    padding: '20px',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.3)',
  };

  const overlayStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    zIndex: 999,
  };

  return (
    <div>
      {isPopupOpen && <div style={overlayStyle} onClick={togglePopup}></div>}
      <div className="footer" style={footerStyle}>
        <div className="footer-column" style={columnStyle}>
          <h3 style={headingStyle}>Quick Links</h3>
          {['About', 'SoftwareDevelopment', 'Gaming', 'HireDeveloper', 'Career'].map((link, index) => (
            <a key={index} href={`/${link.toLowerCase().replace(' ', '-')}`} style={linkStyle}>
              {link}
            </a>
          ))}
        </div>
        <div className="footer-column" style={columnStyle}>
          <h3 style={headingStyle}>Services</h3>
          {[
            'SoftwareDevelopment',
            'ECommerceServices',
            'DigitalMarketing',
            'CRMDevelopment',
            'FullStack',
            'ContentMarketing',
            'ArtificialIntelligence',
          ].map((service, index) => (
            <a key={index} href={`/${service.toLowerCase().replace(' ', '-')}`} style={linkStyle}>
              {service}
            </a>
          ))}
        </div>
        <div className="footer-column" style={columnStyle}>
          <h3 style={headingStyle}>Locate Us</h3>
          <div style={locationStyle}>
            <span>🇨🇦 Toronto, Canada</span>
            <span>🇺🇸 Miami, USA</span>
            <span>🇮🇳 Jaipur, India</span>
            <span>🇮🇳 Gurugram, India</span>
          </div>
          <div style={indiaFlagContainerStyle}>
            <img src={kotiboxlogowhite} alt="Logo" style={logoStyle} />
            <img src="https://upload.wikimedia.org/wikipedia/en/4/41/Flag_of_India.svg" alt="India Flag" style={indiaFlagStyle} />
          </div>
        </div>
        <div className="lower-content" style={lowerContentStyle}>
          {!isPopupOpen ? (
            <div style={projectEnquiryButtonStyle} onClick={togglePopup}>
              Project Enquiry
            </div>
          ) : (
            <div style={formPopupStyle}>
              <button style={{ position: 'absolute', top: '10px', right: '10px' }} onClick={togglePopup}>
                X
              </button>
              <form onSubmit={onSubmit}>
                <input type="tel" name="phone" placeholder="Phone Number" style={{ width: '100%', marginBottom: '10px', padding: '10px' }} required />
                <input type="email" name="email" placeholder="Email" style={{ width: '100%', marginBottom: '10px', padding: '10px' }} required />
                <textarea name="message" placeholder="Message" style={{ width: '100%', marginBottom: '10px', padding: '10px' }} required />
                <button type="submit" style={{ width: '100%', padding: '10px', backgroundColor: '#ffc107' }}>Submit</button>
              </form>
              <span style={{ color: 'white', marginTop: '10px', display: 'block' }}>{result}</span>
            </div>
          )}
          <div style={contactDetailsStyle}>
            <p><strong>Contact Us:</strong></p>
            <p>Email: business.kotiboxglobaltech@gmail.com</p>
            <p>Phone: +91 78520 17051</p>
          </div>
          <div style={socialMediaContainerStyle}>
            <a href="https://www.instagram.com/kotibox_global_/" target="_blank" rel="noopener noreferrer" style={socialIconStyle}>
              <FaInstagram />
            </a>
            <a href="https://www.linkedin.com/company/kotibox/mycompany/" target="_blank" rel="noopener noreferrer" style={socialIconStyle}>
              <FaLinkedin />
            </a>
          </div>
        </div>
      </div>
      <div style={bottomFooterStyle}>
        &copy; 2024 Kotibox Global Technologies. All rights reserved.
      </div>
    </div>
  );
};

export default CustomFooter;
