import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMobileAlt, faCogs, faSyncAlt } from '@fortawesome/free-solid-svg-icons';

import PageTwoContent from './PageTwoContent';
import PageThreeContent from './PageThreeContent';
import PageFourContent from './PageFourContent';
import PagefiveContent from './PageFiveContent';
import Services from '../../imgvdo/Services.mp4';

// Styled Components for responsiveness

const BackgroundVideo = styled.video`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 40px;
  flex-wrap: wrap;
  gap: 20px;

  @media (max-width: 1200px) {
    flex-direction: column;
    padding: 20px;
  }
`;

const LeftSection = styled.div`
  max-width: 48%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  padding: 20px;
  color: white;
  margin-left: 2%;
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);

  h1, h2 {
    margin: 10px 0;
    line-height: 1.2;
    text-transform: uppercase;
    font-weight: bold;
  }

  h1 {
    font-size: 3rem;
    color: black;
    text-shadow: 2px 2px 10px rgba(255, 102, 0, 0.6), -1px -1px 5px rgba(0, 0, 0, 0.7);
  }

  h2 {
    font-size: 2rem;
    color: black;
    text-shadow: 2px 2px 10px rgba(255, 204, 0, 0.6), -1px -1px 5px rgba(0, 0, 0, 0.7);
  }

  div {
    margin-top: 20px;
  }

  @media (max-width: 1200px) {
    max-width: 100%;
    margin-left: 0;
    padding: 20px;

    h1 {
      font-size: 2.5rem;
    }

    h2 {
      font-size: 1.8rem;
    }
  }

  @media (max-width: 768px) {
    padding: 15px;

    h1 {
      font-size: 2rem;
    }

    h2 {
      font-size: 1.5rem;
    }
  }

  @media (max-width: 480px) {
    padding: 10px;

    h1 {
      font-size: 1.8rem;
    }

    h2 {
      font-size: 1.2rem;
    }
  }
`;


const RightSection = styled.div`
  background-color: rgba(51, 51, 51, 0.9);
  color: #fff;
  padding: 15px;
  border-radius: 10px;
  max-width: 48%;
  margin-right: 20vh;

  @media (max-width: 1200px) {
    max-width: 100%;
    margin-right: 0;
    padding: 20px;
  }

  @media (max-width: 768px) {
    padding: 15px;
  }

  @media (max-width: 480px) {
    padding: 10px;
  }
`;

const RightSectionHeading = styled.h3`
  color: #ff6600;
  font-size: 20px;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    font-size: 18px;
  }

  @media (max-width: 480px) {
    font-size: 16px;
  }
`;

const FeatureItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

const FeatureIcon = styled(FontAwesomeIcon)`
  font-size: 18px;
  margin-right: 8px;
  color: #ff6600;
`;

const FeatureLabel = styled.span`
  color: white;
  transition: color 0.3s ease;

  &:hover {
    color: black;
  }
`;

const SubmitBtn = styled.button`
  background-color: #ff6600;
  color: white;
  padding: 8px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  font-size: 13px;

  &:hover {
    background-color: #e65c00;
  }

  @media (max-width: 768px) {
    font-size: 12px;
  }

  @media (max-width: 480px) {
    font-size: 12px;
  }
`;

const ContentMarketing = ({ 
  heading1 = 'Website Development', 
  heading2 = 'It offers end-to-end solutions', 
  icon1 = faMobileAlt, 
  icon2 = faCogs, 
  icon3 = faSyncAlt ,
  label1 = 'Content Creation',
  label2 = 'Multi-Channel Reach',
  label3 = 'Conversion Focused'
}) => {
    return (
        <div style={{ position: 'relative', overflow: 'hidden', color: 'rgb(255, 255, 255)', margin: 0, fontFamily: 'Arial, sans-serif' }}>
            <BackgroundVideo autoPlay loop muted>
                <source src="https://videos.pexels.com/video-files/7966582/7966582-uhd_2560_1440_25fps.mp4" />
                Your browser does not support the video tag.
            </BackgroundVideo>
            
            <Container>
            <LeftSection>
                    <h1>Content Marketing</h1>
                    <h2>Targeted Content Marketing Strategies</h2>
                    <div>
                        {[ 
                            { icon: icon1, label: label1 },
                            { icon: icon2, label: label2 },
                            { icon: icon3, label: label3 },
                        ].map((feature, index) => (
                            <FeatureItem key={index}>
                                <FeatureIcon icon={feature.icon} />
                                <FeatureLabel>{feature.label}</FeatureLabel>
                            </FeatureItem>
                        ))}
                    </div>
                </LeftSection>

                <RightSection>
                    <RightSectionHeading>Book Free Consultation</RightSectionHeading>
                    <p>Fill Out the Form and Our Experts Will Contact You Within 24hrs.</p>
                    <form>
                        {[ 
                            { label: "Full Name*", id: "full-name", type: "text", required: true },
                            { label: "Email*", id: "email", type: "email", required: true },
                            { label: "Phone Number*", id: "phone-number", type: "tel", required: true },
                            { label: "Company Name", id: "company-name", type: "text" },
                        ].map((field, index) => (
                            <div key={index}>
                                <label htmlFor={field.id}>{field.label}</label>
                                <input id={field.id} name={field.id} type={field.type} required={field.required} style={{ width: '100%', padding: '6px', border: 'none', borderRadius: '5px', marginTop: '4px', fontSize: '13px', backgroundColor: '#444', color: 'white' }} />
                            </div>
                        ))}
                        <div>
                            <label htmlFor="requirement">Your Requirement*</label>
                            <textarea id="requirement" name="requirement" required style={{ width: '100%', height: '70px', padding: '6px', border: 'none', borderRadius: '5px', marginTop: '4px', fontSize: '13px', backgroundColor: '#444', color: 'white' }}></textarea>
                        </div>
                        <SubmitBtn type="submit">Submit Your Requirement!</SubmitBtn>
                    </form>
                </RightSection>
            </Container>

            <PageTwoContent/>
            <PagefiveContent/>
            <PageThreeContent/>
            <PageFourContent/>
        </div>
    );
}

export default ContentMarketing;