import React from 'react';
import banner from '../../imgvdo/banner.gif'; // Import image from assets folder

const PageSevenWb = () => {
  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: '70vh', // Adjusted from 100vh to 70vh to reduce page height
      width: '100%',
      backgroundColor: '#000', // Black background for a premium look
      color: '#fff', // White text color for contrast
      
      textAlign: 'center',
    },
    content: {
      maxWidth: '900px',
      marginBottom: '20px',
    },
    heading: {
      fontSize: '25px', 
      fontWeight: 'bold',
      marginBottom: '10px',
      textTransform: 'uppercase',
      letterSpacing: '2px',
    },
    subheading: {
      fontSize: '18px', 
      fontWeight: 'normal',
      color: '#f1c40f', // Premium gold color for the subheading
    },
    imageContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
    },
    image: {
      maxWidth: '100%',
      height: 'auto',
      borderRadius: '15px', // Smooth corners for a premium feel
      boxShadow: '0 10px 20px rgba(0,0,0,0.5)', // Premium shadow effect
    },

    // Media Queries for Responsive Design
    '@media (max-width: 768px)': {
      heading: {
        fontSize: '22px',
      },
      subheading: {
        fontSize: '16px',
      },
    },

    '@media (max-width: 480px)': {
      heading: {
        fontSize: '20px',
      },
      subheading: {
        fontSize: '14px',
      },
      container: {
        minHeight: '60vh', // Reduce height for smaller screens
      },
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.content}>
        <h1 style={styles.heading}>Do you Know how we Build Website</h1>
        <p style={styles.subheading}>Process</p>
      </div>
      <div style={styles.imageContainer}>
        <img src={banner} alt="Banner" style={styles.image} />
      </div>
    </div>
  );
};

export default PageSevenWb;
