import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMobileAlt, faCogs, faSyncAlt } from '@fortawesome/free-solid-svg-icons';

import PageTwoApp from './PageTwoApp';
import PageThreeApp from './PageThreeApp';
import PageFourApp from './PageFourApp';
import MobileAppDevelopment from './MobileAppDevelopment';
import PageFiveApp from './PageFiveApp';
import PageSixApp from './PageSixApp';


// Styled Components for responsiveness and effects
const BackgroundVideo = styled.video`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch; /* Ensure both sections stretch to equal height */
  padding: 40px;
  flex-wrap: wrap;
  gap: 10px; /* Decreased gap between sections */
  animation: fadeIn 1s ease-in;

  @media (max-width: 1200px) {
    flex-direction: column;
    padding: 20px;
    gap: 20px;
  }
`;

const Section = styled.div`
  flex: 1;
  max-width: 48%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  padding: 30px;
  margin: 7px;
  background: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease-in-out;
  
  /* Ensure equal height */
  display: flex;
  flex-direction: column;
  height: 100%;
  
  &:hover {
    transform: scale(1.02);
  }

  h1 {
    font-size: 36px;
    margin-bottom: 20px;
    color: #ff6600;
    text-transform: uppercase;
    font-weight: bold;
    text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.6);
    animation: slideInLeft 0.6s ease-out;
  }

  h2 {
    font-size: 24px;
    margin-bottom: 30px;
    font-weight: lighter;
    color: #ddd;
    animation: slideInLeft 0.8s ease-out;
  }

  @media (max-width: 1200px) {
    max-width: 100%;
    margin-left: 0;
    padding: 20px;
  }

  @media (max-width: 768px) {
    padding: 15px;
  }

  @media (max-width: 480px) {
    padding: 10px;
  }
`;

const RightSection = styled.div`
  background-color: rgba(51, 51, 51, 0.9);
  color: #fff;
  padding: 25px;
  border-radius: 10px;
  max-width: 48%;
  margin-right: 10px; /* Reduced margin */
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease-in-out;
  
  /* Ensure equal height */
  display: flex;
  flex-direction: column;
  height: 100%;
  
  &:hover {
    transform: scale(1.02);
  }

  @media (max-width: 1200px) {
    max-width: 100%;
    margin-right: 0;
    padding: 20px;
  }

  @media (max-width: 768px) {
    padding: 15px;
  }

  @media (max-width: 480px) {
    padding: 10px;
  }
`;

const RightSectionHeading = styled.h3`
  color: #ff6600;
  font-size: 20px;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    font-size: 18px;
  }

  @media (max-width: 480px) {
    font-size: 16px;
  }
`;

const FeatureItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  animation: fadeInUp 1s ease-out;
`;

const FeatureIcon = styled(FontAwesomeIcon)`
  font-size: 22px;
  margin-right: 15px;
  color: #ff6600;
  transition: transform 0.3s ease;

  &:hover {
    transform: rotate(15deg);
  }
`;

const FeatureLabel = styled.span`
  color: white;
  font-size: 18px;
  font-weight: 500;
  transition: color 0.3s ease;

  &:hover {
    color: #ffd600;
  }
`;

const DescriptionText = styled.p`
  font-size: 16px;
  margin-bottom: 20px;
  color: #ddd;
  line-height: 1.6;
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
`;

const SubmitBtn = styled.button`
  background-color: #ff6600;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #e65c00;
  }

  @media (max-width: 768px) {
    font-size: 14px;
  }

  @media (max-width: 480px) {
    font-size: 14px;
  }
`;

// Keyframes for animations
const fadeIn = `
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const slideInLeft = `
  @keyframes slideInLeft {
    0% {
      transform: translateX(-100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
`;

const fadeInUp = `
  @keyframes fadeInUp {
    0% {
      transform: translateY(30px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
`;

const AppDevelopment = ({ 
  icon1 = faMobileAlt, 
  icon2 = faCogs, 
  icon3 = faSyncAlt,
  label1 = 'Cross-Platform',
  label2 = 'Custom UI',
  label3 = 'Maintenance & Scalable'
}) => {
  return (
    <div style={{ position: 'relative', overflow: 'hidden', margin: 0, fontFamily: 'Arial, sans-serif' }}>
      <BackgroundVideo autoPlay loop muted>
        <source src="https://videos.pexels.com/video-files/946147/946147-hd_1920_1080_30fps.mp4" />
        Your browser does not support the video tag.
      </BackgroundVideo>
      
      <Container>
        <Section>
          <h1>Mobile App Development</h1>
          <h2>Custom Mobile App Development Solutions</h2>
          <DescriptionText>
            We specialize in creating high-performance mobile applications that engage users with seamless experiences.
          </DescriptionText>
          
          <div>
            {[ 
              { icon: icon1, label: label1, description: 'Develop apps that work across multiple platforms with ease.' },
              { icon: icon2, label: label2, description: 'Craft tailored, visually appealing UIs for great user engagement.' },
              { icon: icon3, label: label3, description: 'Ensure your app scales seamlessly as your user base grows.' }
            ].map((feature, index) => (
              <FeatureItem key={index}>
                <FeatureIcon icon={feature.icon} />
                <div>
                  <FeatureLabel>{feature.label}</FeatureLabel>
                  <DescriptionText>{feature.description}</DescriptionText>
                </div>
              </FeatureItem>
            ))}
          </div>
        </Section>

        <RightSection>
          <RightSectionHeading>Book a Free Consultation</RightSectionHeading>
          <p>Fill out the form and one of our experts will contact you within 24 hours.</p>
          <form>
            {[ 
              { label: "Full Name*", id: "full-name", type: "text", required: true },
              { label: "Email*", id: "email", type: "email", required: true },
              { label: "Phone Number*", id: "phone-number", type: "tel", required: true },
              { label: "Company Name", id: "company-name", type: "text" },
            ].map((field, index) => (
              <div key={index}>
                <label htmlFor={field.id}>{field.label}</label>
                <input 
                  id={field.id} 
                  name={field.id} 
                  type={field.type} 
                  required={field.required} 
                  style={{ width: '100%', padding: '6px', border: 'none', borderRadius: '5px', marginTop: '4px', fontSize: '13px', backgroundColor: '#444', color: 'white' }} 
                />
              </div>
            ))}
            <div>
              <label htmlFor="requirement">Your Requirement*</label>
              <textarea 
                id="requirement" 
                name="requirement" 
                required 
                style={{ width: '100%', height: '70px', padding: '6px', border: 'none', borderRadius: '5px', marginTop: '4px', fontSize: '13px', backgroundColor: '#444', color: 'white' }} 
              ></textarea>
            </div>
            <SubmitBtn type="submit">Submit Your Requirement!</SubmitBtn>
          </form>
        </RightSection>
      </Container>

      <PageTwoApp />
      <MobileAppDevelopment />
      <PageThreeApp />
      <PageFourApp />
      <PageFiveApp />
      <PageSixApp />
    </div>
  );
};

export default AppDevelopment;
