import React, { useState } from 'react';

const ContactForm = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log({ name, email, message });
    };

    return (
        <>
            <style>
                {`
                    .contact-form-container {
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-between;
                        align-items: flex-start;
                        padding: 50px;
                        background-color: #0f0f0f;
                        color: white;
                        gap: 20px;
                    }

                    .form-content {
                        flex: 1;
                        max-width: 40%;
                        margin-bottom: 20px;
                    }

                    .heading {
                        font-size: 2.5rem;
                        margin-bottom: 20px;
                    }

                    .paragraph {
                        font-size: 1.2rem;
                        margin-bottom: 20px;
                    }

                    .still-not-sure {
                        font-size: 1rem;
                    }

                    .link {
                        color: #ffc107;
                        text-decoration: none;
                    }

                    .form-fields {
                        flex: 1;
                        width: 100%;
                        max-width: 600px;
                    }

                    .form-group {
                        position: relative;
                        margin-bottom: 20px;
                    }

                    .label {
                        display: block;
                        font-size: 1.1rem;
                        margin-bottom: 5px;
                    }

                    .input, .textarea {
                        width: 100%;
                        padding: 12px;
                        border-radius: 8px;
                        border: 1px solid #333;
                        background-color: #1c1c1c;
                        color: white;
                        font-size: 1rem;
                    }

                    .textarea {
                        height: 150px;
                        resize: none;
                    }

                    .required-star {
                        position: absolute;
                        right: 10px;
                        top: 50%;
                        transform: translateY(-50%);
                        color: #ffc107;
                        font-size: 1.5rem;
                    }

                    .submit-button {
                        background-color: transparent;
                        color: white;
                        border: 2px solid #ffc107;
                        padding: 12px 30px;
                        border-radius: 30px;
                        cursor: pointer;
                        font-size: 1.2rem;
                        transition: background-color 0.3s ease-in-out;
                    }

                    .submit-button:hover {
                        background-color: #ffc107;
                        color: #0f0f0f;
                    }

                    /* Media Queries */
                    @media (max-width: 768px) {
                        .contact-form-container {
                            flex-direction: column;
                            align-items: center;
                            padding: 30px;
                        }

                        .form-content {
                            max-width: 100%;
                            text-align: center;
                        }

                        .heading {
                            font-size: 2rem;
                        }

                        .paragraph {
                            font-size: 1rem;
                        }

                        .form-fields {
                            max-width: 100%;
                        }
                    }

                    @media (max-width: 480px) {
                        .contact-form-container {
                            padding: 20px;
                        }

                        .heading {
                            font-size: 1.8rem;
                        }

                        .paragraph {
                            font-size: 0.9rem;
                        }

                        .input, .textarea {
                            padding: 10px;
                            font-size: 0.9rem;
                        }

                        .submit-button {
                            font-size: 1rem;
                            padding: 10px 20px;
                        }
                    }
                `}
            </style>
            <div className="contact-form-container">
                <div className="form-content">
                    <h2 className="heading">Let's Talk</h2>
                    <p className="paragraph">
                        Discuss your vision or scale up your entrepreneurial journey. We are only this form away.
                    </p>
                    <p className="still-not-sure">
                        Are you still not sure? <a href="#" className="link">CHECK THIS</a>
                    </p>
                </div>

                <div className="form-fields">
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label htmlFor="name" className="label">Your Name</label>
                            <input
                                type="text"
                                name="name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                placeholder="Enter your name..."
                                required
                                className="input"
                            />
                            <span className="required-star">*</span>
                        </div>

                        <div className="form-group">
                            <label htmlFor="email" className="label">Your Email</label>
                            <input
                                type="email"
                                name="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Enter your email..."
                                required
                                className="input"
                            />
                            <span className="required-star">*</span>
                        </div>

                        <div className="form-group">
                            <label htmlFor="message" className="label">Your Message</label>
                            <textarea
                                name="message"
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                placeholder="Enter your message..."
                                required
                                className="textarea"
                            ></textarea>
                            <span className="required-star">*</span>
                        </div>

                        <button type="submit" className="submit-button">SEND</button>
                    </form>
                </div>
            </div>
        </>
    );
};

export default ContactForm;
